import {
    NAV_STYLE_DRAWER,
    NAV_STYLE_FIXED,
    NAV_STYLE_MINI_SIDEBAR,
    TAB_SIZE
} from "../../constants/ThemeSetting";
import React, { Component } from "react";
import {
    onNavStyleChange,
    toggleCollapsedSideNav
} from "appRedux/actions/Setting";
import { PROJECT_ROUTE } from "../../constants/Common";
import { ReactComponent as Logo } from "../../assets/images/voltloader.svg";

import { Link } from "react-router-dom";
import { connect } from "react-redux";

class SidebarLogo extends Component {
    render() {
        const { width } = this.props;
        let { navStyle } = this.props;
        if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
            navStyle = NAV_STYLE_DRAWER;
        }

        return (
            <div className="gx-layout-sider-header">
                {navStyle === NAV_STYLE_FIXED ||
                navStyle === NAV_STYLE_MINI_SIDEBAR ? (
                    <div className="gx-linebar" />
                ) : null}
                <Link
                    to={`/${PROJECT_ROUTE}/dashboard`}
                    className="gx-site-logo"
                >
                    <Logo />
                </Link>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    const { navStyle, themeType, width, navCollapsed } = settings;

    return { navStyle, themeType, width, navCollapsed };
};

export default connect(mapStateToProps, {
    onNavStyleChange,
    toggleCollapsedSideNav
})(SidebarLogo);
