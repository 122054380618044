import * as _ from "lodash";
import moment from "moment-timezone";
import {
    DEFAULT_LANGUAGE,
    LANGUAGES_NAME,
    DEFAULT_BASE_CURRENCY,
    DEFAULT_DISTANCE_UNIT,
    DISTANCE_UNIT,
    DECIMAL_POINT
} from "../constants/Common";
const UtilService = {
    getKeyByValue: (object, value) => {
        return _.findKey(object, _.partial(_.isEqual, value));
        // return Object.keys(object).find(key => object[key] === value);
    },
    getPrimaryValue: (array, key) => {
        return array && array.length
            ? _.find(array, { isPrimary: true })
                ? _.find(array, { isPrimary: true })[key]
                : ""
            : "";
    },
    getMobileVerifiedValue: (array, key) => {
        return array && array.length
            ? _.find(array, { isVerified: true })
                ? _.find(array, { isVerified: true })[key]
                : ""
            : "";
    },
    getMobileUnverifiedValue: (array, key) => {
        return array && array.length
            ? _.find(array, { isVerified: false })
                ? _.find(array, { isVerified: false })[key]
                : ""
            : "";
    },
    getPrimaryObj: array => {
        return array && array.length ? _.find(array, { isPrimary: true }) : "";
    },
    displayDate: (date, hour24 = false) => {
        let timeStr = "hh:mm A";
        if (hour24) timeStr = "HH:mm";
        return date ? moment(date).format("MMM DD, YYYY " + timeStr) : "-";
    },
    displayTime: (date, hour24 = false) => {
        let timeStr = "hh:mm A";
        if (hour24) {
            timeStr = "HH:mm";
        }

        return date ? moment(date).format(timeStr) : "-";
    },

    displayOnlyDate: date => {
        return date ? moment(date).format("MMM DD, YYYY") : "-";
    },
    displayUserDOB: (date, hour24 = false) => {
        let timeStr = "hh:mm a";
        if (hour24) {
            timeStr = "HH:mm";
        }

        return date
            ? moment(date, "MM-DD-YYYY").format(`MMM DD, YYYY ${timeStr}`)
            : "-";
    },

    displayDateFormate: (date, hour24 = false) => {
        return date ? moment(date).format("MM-DD-YYYY") : "-";
    },
    displayEndDate: (date, hour24 = false, format = null) => {
        if (!format) {
            format = "MM-DD-YYYY";
        }
        return date ? moment(date).format(format) : "-";
    },
    displayDateTime: (date, hour24 = false) => {
        let timeStr = "hh:mm A";
        if (hour24) timeStr = "HH:mm";
        let zone = moment.tz.guess();
        return date ? moment(date).tz(zone).format("MM-DD-YYYY, " + timeStr) : "-";
    },

    addHourMinDate: (date, hour, min) => {
        let dateString = moment.utc(date).add(hour, "hours").add(min, 'minutes').toISOString();

        let zone = moment.tz.guess()
        return dateString ? moment(dateString).tz(zone).format('MM-DD-YYYY hh:mm A') : "-";
    },
    formatDateTz: (date, format) => {
        let dateString = moment.utc(date).toISOString();
        if (!format) {
            format = 'MM-DD-YYYY hh:mm A';
        }
        let zone = moment.tz.guess()
        return dateString ? moment(dateString).tz(zone).format(format) : "-";
    },
    addHourMinDateSecondTz: (date, hour, min, sec) => {
        let dateString = moment.utc(date).add(hour, "hours").add(min, 'minutes').add(sec, "seconds").toISOString();
        let zone = moment.tz.guess()
        console.log(`zone`, zone)
        return dateString ? moment(dateString).tz(zone).format('MM-DD-YYYY hh:mm A') : "-";
    },
    addSecondDate: (date, second) => {
        let dateString = moment(date).format("YYYY-MM-DD");
        dateString = moment(dateString).add(second, "seconds").toISOString();
        return dateString ? moment(dateString).format("MM-DD-YYYY, hh:mm A") : "-";
    },

    displayDOB: date => {
        if (!date) {
            return "-";
        }
        const age = moment().diff(moment(date, "MM-DD-YYYY"), "years");

        return `${moment(date, "MM-DD-YYYY").format(
            "Do MMM. YYYY"
        )} (${age} Year)`;
    },
    displayFromNow: date => {
        return date ? moment(date).fromNow() : "-";
    },
    getDayHour: s => {
        var d = Number(s);
        var h = Math.floor(d / 3600);
        var m = Math.floor((d % 3600) / 60);
        var day = 0;
        var str = "";
        if (h >= 24) {
            day = Math.floor(h / 24);
            h = h - day * 24;
        }
        if (day > 0) str = str + day + " d ";
        if (h > 0) str = str + h + " h ";
        if (m > 0) str = str + m + " m";
        return str;
    },
    getDays: date => {
        return date ? `${moment().diff(date, "days")} days ago` : null;
    },
    getKiloMeter: value => {
        return value ? value / 1000 : 0;
    },
    getMinutes: value => {
        var d = Number(value);
        var min = Math.floor((d % 3600) / 60);
        return min;
    },
    copyToClipboardFn: (event, context) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const el = document.createElement("textarea");
        el.value = JSON.parse(JSON.stringify(context));

        el.setAttribute("readonly", "");
        el.style.position = "absolute";
        el.style.left = "-9999px";

        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
    },
    getDayIn1970: dateTime => {
        return moment(
            `01/01/1970 ${moment(dateTime).format("HH:mm")}:00`
        ).toISOString();
    },
    checkTimeIsSameOrBefore: (afterTime, beforeTime) => {
        return moment(afterTime).isSameOrBefore(beforeTime);
    },
    checkTimeIsSameOrAfter: (afterTime, beforeTime) => {
        return moment(afterTime).isSameOrAfter(beforeTime);
    },
    checkTimeIsBefore: (afterTime, beforeTime) => {
        return moment(afterTime).isBefore(beforeTime);
    },
    checkTimeIsAfter: (afterTime, beforeTime) => {
        return moment(afterTime).isAfter(beforeTime);
    },
    checkTimeIsSame: (afterTime, beforeTime) => {
        var timeTockeck = moment(afterTime).format('YYYY-MM-DD');
        return moment(timeTockeck).isSame(moment(beforeTime).format('YYYY-MM-DD'));
    },
    getStartOfTheDayIn1970() {
        return moment(`01/01/1970 00:00:00`).toISOString();
    },
    getEndOfTheDayIn1970() {
        return moment(`01/01/1970 23:59:00`).toISOString();
    },
    getStartOfTheDay(date) {
        return `${moment(date).startOf('days').toISOString()}`
        //${moment(date).format("YYYY-MM-DD")}T00:00:00.000Z`;
    },
    getEndOfTheDay(date) {
        return `${moment(date).endOf('days').toISOString()}`
        //`${moment(date).format("YYYY-MM-DD")}T23:59:59.999Z`;
    },
    getSecondsToHms(d) {
        let secs = d;
        // secs *= 60;
        let formatted = moment.utc(secs * 1000).format("HH:mm:ss");

        return formatted;
    },
    roundOff(number, lenth = 2) {
        return Number(parseFloat(number).toFixed(lenth));
    },

    getKeysNameFromVal(val, obj) {
        let name = "";
        name = _.findKey(obj, _.partial(_.isEqual, val));

        return name;
    },
    getKeysNameFromValForDisplay(val, obj) {
        let name = "";
        name = _.findKey(obj, _.partial(_.isEqual, val));
        name = _.upperFirst(_.lowerCase(name));

        return name;
    },
    setFormDataForLanguage(fields, language, data) {
        let values = data || {};
        for (let field of fields) {
            if (data && data[field]) {
                if (!values.multiLanguageData) {
                    values.multiLanguageData = {};
                }
                if (!values.multiLanguageData[language]) {
                    values.multiLanguageData[language] = {};
                }
                values.multiLanguageData[language][field] = data[field];
                if (!values.multiLanguageData[DEFAULT_LANGUAGE]) {
                    values.multiLanguageData[DEFAULT_LANGUAGE] = {};
                }
                values[field] =
                    values.multiLanguageData[DEFAULT_LANGUAGE][field] || null;
            }
        }

        return values;
    },
    getLanguageValues(fields, language, data) {
        // data = multiLanguageData
        const values = {};

        for (let field of fields) {
            values[field] = null;
            if (data && data[language] && data[language][field]) {
                values[field] = data[language][field];
            }
        }

        return values;
    },
    defaultLanguageDataValidation(fields, data) {
        for (const field of fields) {
            if (!data.multiLanguageData[DEFAULT_LANGUAGE][field]) {
                let message = `${field} value is required for
                    ${LANGUAGES_NAME[DEFAULT_LANGUAGE]} Language.
                `;

                return message;
            }
        }

        return true;
    },
    displayNumber: value => {
        return Number(parseFloat(value).toFixed(2));
    },
    displayPrice(price = 0) {
        return `${DEFAULT_BASE_CURRENCY} ${this.displayNumber(price)}`;
    },
    displayTwoDigit: value => {
        // return (
        //     <Tooltip title={value}>
        //         {value.toFixed(2)}
        //     </Tooltip>
        // );
    },
    checkAlphaChar: pwd => {
        const letter = /[a-zA-Z]/;
        const valid = letter.test(pwd);
        return valid;
    },
    checkAlphaNumericPassword: pwd => {
        const letter = /[a-zA-Z]/;
        const number = /[0-9]/;
        const valid = number.test(pwd) && letter.test(pwd);
        return valid;
    },
    checkAlphNemeric: code => {
        const regEx = /^[a-zA-Z0-9]+$/;
        const valid = regEx.test(code);
        return valid;
    },
    checkSpecialCharNotAllow: code => {
        const letter = /[-_ a-zA-Z0-9]+$/;
        const valid = letter.test(code);
        return valid;
    },
    convertHourToSeconds: hours => {
        return Math.floor(hours * 60 * 60);
    },
    convertHour: hour => {
        if (hour) {
            let h = new Date(hour).toLocaleString("en-IN", {
                hour: "numeric",
                hour12: true
            });
            return h;
        }
    },
    commonFilter(filterObject) {
        let data = {};
        if (filterObject.newData && filterObject.newData !== "") {
            filterObject.listData.map(filt => {
                if (filt.id === filterObject.selectedVal) {
                    data = filt;
                }
                return true;
            });
        } else {
            data = _.find(filterObject.listData, {
                value: filterObject.selectedVal
            });
        }
        let sortBy;
        let response;
        if (data) {
            sortBy = data.type
                ? filterObject.lowHigh
                    ? filterObject.isAscending === "low"
                        ? `${data.type}:1`
                        : `${data.type}:-1`
                    : filterObject.isAscending
                        ? `${data.type} ASC`
                        : `${data.type} DESC`
                : data.code && data.code !== ""
                    ? filterObject.isAscending
                        ? `${data.type} ASC`
                        : `${data.type} DESC`
                    : "error";
            response = filterObject.key === "sort" ? sortBy : data;
        }
        if (response) {
            return response;
        } else {
            return "error";
        }
    },
    getDefaultValue(commonArray, filterData) {
        let result = _.find(commonArray, f => f.type === filterData);
        return result.value;
    },
    getHoursFromMinute(sec) {
        //Seconds to hour conversion
        // let d = Number(sec);
        let rhours = Math.floor(sec / 3600);
        let rminutes = Math.floor((sec % 3600) / 60);
        let rseconds = Math.floor((sec % 3600) % 60);

        if (rhours.toString().length === 1) {
            rhours = `0${rhours}`;
        }
        if (rminutes.toString().length === 1) {
            rminutes = `0${rminutes}`;
        }
        if (rseconds.toString().length === 1) {
            rseconds = `0${rseconds}`;
        }
        // Minute to hour conversion
        // let hours = minu / 60;
        // let rhours = Math.floor(hours);
        // let minutes = (hours - rhours) * 60;
        // let rminutes = Math.round(minutes);
        return `${rhours}h : ${rminutes}m : ${rseconds}s`;
    },
    getDeviceId() {
        let deviceId = `volt${Math.random()
            .toString(36)
            .replace(".", 1)}`;
        return deviceId.slice(0, 15);
    },
    randomColor() {
        let hex = Math.floor(Math.random() * 0xffffff);
        let color = "#" + hex.toString(16);
        return color;
    },
    convertMeterToDefaultDistanceType(meters) {
        if (meters <= 0) {
            return 0;
        }
        let unit = "KM";
        let distanceDivider = 1000;
        if (DEFAULT_DISTANCE_UNIT === DISTANCE_UNIT.MILES) {
            distanceDivider = 1609;
            unit = "Miles";
        }
        let distance = meters / distanceDivider;
        return _.round(distance, DECIMAL_POINT)// + " " + unit;
    },
    getStartDateTime(date) {
        return `${moment(date).utc().format("YYYY-MM-DD")}T00:00:00.000Z`
    },
    setFromDate(date) {
        let a = moment.tz(date, moment.tz.guess());
        return `${moment(a).format("YYYY-MM-DD")}T00:00:00.000Z`;
    }
};

export default UtilService;
