import React from "react";
import axios from "util/Api";
import _ from "lodash";

class VerifyEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "enail is verify"
        };
    }
    componentDidMount() {
        this.Initial();
    }
    Initial = async () => {
        let pathName = window.location.pathname.split("/");
        let lastPathName = _.last(pathName);
        try {
            let response = await axios.get(
                `api/v1/driver/verify-email/${lastPathName}`,
                this.state.filter
            );
            if (response && response.code === "OK") {
                this.setState({ message: response.message });
            } else {
                this.setState({ message: response.message });
            }
        } catch (error) {
            console.log("Error****:", error.message);
        }
    };

    render() {
        return (
            <div className="verifyEmail">
                <h1 className="veriFyEmail-heading">{this.state.message}</h1>
            </div>
        );
    }
}

export default VerifyEmail;
