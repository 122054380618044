export const PAGE_PERMISSION = {
    VOLT_DASHBOARD: 101,
    VOLT_MASTER: 102,
    VOLT_SUB_MASTER: 103,
    VOLT_SETTINGS: 104,
    VOLT_DRIVERS: 105,
    VOLT_CUSTOMERS: 106,
    VOLT_VEHICLES: 107,
    VOLT_FARE_CONFIG: 108,
    VOLT_TIER: 109,
    VOLT_FOS: 110,
    VOLT_STATIC_PAGE: 111,
    VOLT_VERSION: 112,
    VOLT_ROLES: 113,
    VOLT_TIME_SHIFT: 114,
    VOLT_MEMBER_SHIP: 115,
    VOLT_RIDE_DETAILS: 116,
    VOLT_DELIVERY_HISTORY: 117,
    VOLT_RIDE_SUMMARY: 118,
    VOLT_REJECTED_RIDES: 119,
    VOLT_SCHEDULE_HISTORY: 120,
    VOLT_RECURRING_HISTORY: 121,
    VOLT_DISPUTE: 122,
    VOLT_TIME_SHEET_SUMMARY: 123,
    VOLT_TIME_SHEET_DETAILS: 124,
    VOLT_ATTENDANCE: 125,
    VOLT_COMMISSION_SUMMARY: 126,
    VOLT_COMMISSION_DETAILS: 127,
    VOLT_VOLT_FARE_COLLECTION: 128,
    VOLT_CASH_COLLECTION: 129,
    VOLT_PROMO_CODE: 130,
    VOLT_TRANSACTION_DETAILS: 131,
    VOLT_NOTIFICATION: 132,
    VOLT_DAILY_REPORT: 133,
    VOLT_DELIVERY_COLLECTION: 134,
    VOLT_RATINGS: 135,
    VOLT_MULTI_DELIVERY: 36,
    VOLT_USERS: 136,
    VOLT_MOBILITY: 137,
    VOLT_REFERRAL : 138,
    VOLT_CUSTOMER_SERVICE : 139,
    VOLT_RECURRING_MULTI_HISTORY: 143,
   
    //----rohak constant
    SHARE2OWN: 140,
    SHARE2OWN_SETTING: 141,
    MICRO_MOBILITY_DELIVERY_HISTORY:142,
    DASHBOARD: 1,
    RIDERS: 2,
    // HEAT_MAP:3,
    VEHICLES: 4,
    // ANALYTICS:5,
    DISPUTE: 6,
    // CONTACT:7,
    SERVICE_REQUEST: 7,
    NOTIFICATIONS: 8,
    ROLES: 9,
    RIDES: 10,

    SUPPORT: 12,
    COMMUNITY_MODE: 13,
    VEHICLE_REPORT: 14,

    USERS: 21,
    FRANCHISEE: 22,
    FEEDER: 23,
    PAYMENT: 33,

    MASTER: 41,
    DATABANK: 42,
    LOCATION: 43,

    RIDE_SETTING: 51,
    CANCELLATION_REASON: 52,
    STATIC_PAGE: 53,
    FARE_MANAGEMENT: 54,
    FAQS: 55,
    PROCEDURE: 56,
    PROMOTIONS: 57,
    // TASK: 58,
    FEEDBACK: 58,
    WALLET_CONFIG: 59,
    ACTION_QUESTIONNAIRE: 60,

    ZONES: 65,
    NEST: 66,
    VERSION: 67,
    TASKSETUP: 68,
    CREATE_TASK: 69,

    SUBSCRIPTION: 81,
    BOOKING_PASS: 82,
    RENTAL: 83,
    RENTAL_PAYMENT: 84,
    RENTAL_PAYMENT_CLIENT: 85,
    GENERAL_SETTINGS: 87,
    GEO_LOCATION: 88,

    ABOUT_US: 90,
    COMMISSION: 91,
    COMMISSION_PAYOUT: 92,
    PRIVACY_POLICY: 93,
    TERMS_AND_CONDITIONS: 94,
    COMMISSION_REPORT: 95,
    DEALER: 97,
    CONTACT_US: 99
};
