export * from "./Sidebar";
export * from "./Setup";
export * from "./RolesMenu";
export * from "./PagePermission";

export const MY_APP_VERSION = 27;

export const PAGES_PERMISSION = {
    1: [1, 10, 2, 21, 33, 4, 53, 54, 57, 58, 6, 8],
    2: [1, 10, 2, 21, 33, 4, 53, 54, 57, 58, 6, 8],
    3: [1, 10, 2, 21, 33, 53, 54, 6, 8],
    4: [1, 10, 2, 33, 53, 54, 6, 8],
    5: [1, 10, 2, 21, 33, 4, 53, 54, 6, 8]
};
export const NAME_SORTER = (a, b, key) => {
    if (a[key] < b[key]) {
        return -1;
    }
    if (a[key] > b[key]) {
        return 1;
    }

    return 0;
};
export const PROJECT_ROUTE = "Volt-Mobility";

export const USER_PATHS = "all|e-auto-admin|sub-admin|staff|passengers";

export const DRIVER_DOCUMENT_TYPE = {
    DRIVING_LICENCE: 1,
    SOCIAL_SECURITY_CARD: 2,
    PASSPORT: 3,
    IDENTITY_DOCUMENT: 4,
    VISA: 5,
    USER_SELFIE: 6,
    DOT_PHYSICAL_CERTIFICATE: 10
};
export const DRIVER_PRESENTS = [
    { label: "Present", value: 1, type: 1 },
    { label: "Absent", value: 2, type: 2 }
];

export const SERVICE_TYPE = {
    Ride: 1,
    Delivery: 2,
    Grocery: 3
};

export const DOCUMENT_SIDE = {
    FRONT: 1,
    BACK: 2
};
export const VEHICLE_DOCUMENT_TYPE = {
    REGISTRATION_CERTIFICATE: 7,
    PROOF_OF_INSURANCE: 8,
    MECHANICAL_INSPECTION_FORM: 9
};
export const INFO_TYPE = {
    STATIC_PAGE: 1,
    SUPPORT: 2
};

export const PASSENGER_TYPES = {
    GENERAL: 1,
    VET: 2,
    ELDERLY: 3,
    DIFFERENTLY_ABLE: 4
};
export const RIDE_CANCELLED_BY = {
    PASSENGER: 1,
    DRIVER: 2,
    SYSTEM: 3
};
export const VERSION_APP_TYPE = {
    PASSENGER: 5,
    DRIVER: 6,
    MERCHANT: 7
};
export const MEMBERSHIP_STATUS = {
    CURRENT: 1,
    UPGRADE: 2,
    DEGRADE: 3,
    CANCELED: 4,
    EXPIRED: 5
};
export const DISPUTE_STATUS_ARRAY = [
    { value: 1, label: "SUBMITTED", type: 1 },
    { value: 2, label: "IN PROCESS", type: 2 },
    { value: 3, label: "RESOLVED", type: 3 },
    { value: 4, label: "CANCELLED", type: 4 }
];
export const DEFAULT_API_ERROR = "Something went wrong. Please contact admin.";

export const VEHICLE_STATUS_ARRAY = [
    { value: 1, label: "RUNNING", displayColor: "var(--es--svg--start)" }, // #bd1a1b
    { value: 2, label: "STANDING", displayColor: "var(--es--menu--hover)" } // #650001
];
export const NOTIFICATION_TYPE = {
    EMAIL: 1,
    SMS: 2,
    PUSH_NOTIFICATION: 3
};
export const DOCUMENT_VERIFICATION_STATUS = {
    PENDING: 0,
    APPROVED: 1,
    REJECTED: 2
};
export const DISCOUNT_UNIT = {
    FLAT: 1,
    PERCENTAGE: 2
};
export const PROMOCODE_SERVICE = {
    NR: 1,
    RS: 2,
    ND: 6
};

export const NOTIFICATION_STATUS = {
    SEND: 1,
    READ: 2
};
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 15;

export const VEHICLE_CONNECTION_TYPES = {
    CONNECTED: true,
    NOT_CONNECTED: false
};

export const STATUS_TYPES = {
    ACTIVE: true,
    DEACTIVE: false
};
export const VERIFY_TYPES = {
    VERIFIED: true,
    UNVERIFIED: false
};

export const VEHICLE_STATUS = {
    RUNNING: 1,
    STANDING: 2
};
export const MICRO_VEHICLE_TYPE = {
    SCOOTER: 1,
    EBIKE: 2
};
export const MICRO_VEHICLE_TYPE_STRING = {
    1: "Scooter",
    2: "EBike"
};
export const PACKAGE_AUTO_TYPE = {
    FREIGHT: 1,
    NORMAL: 2,
    BOTH: 3
};

export const TIME_FILTER = [{ label: "From", value: 1, type: 1 }];

export const START_TIME_FILTER = [{ label: "From", value: 1, type: 1 }];
export const PAYMENT_STATUS = {
    EXPIRED: 2,
    FAILED: 3,
    UNPAID: 4,
    REFUND: 5,
    PAID: 6
};
export const PAYMENT_TYPE = {
    ONLINE: 1,
    CASH: 2,
    WALLET: 3
};
export const PAYMENT_TYPE_STRING = {
    1: "Online",
    3: "Wallet"
};
export const FREQUENCY_TYPE = {
    DAILY: 1,
    WEEKLY: 2,
    CUSTOM: 3,
    ONCE_IN_WEEK: 4
};
export const PAGINATION = [
    {
        label: "All",
        value: 0
    },
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 }
];

export const USER_TYPES = {
    SUPER_ADMIN: 1,
    ADMIN: 2,
    SUB_ADMIN: 3,
    STAFF: 4,
    PASSENGER: 5,
    DRIVER: 6,
    COMPANY: 7
};
export const NOTIFICATION_TYPES = [
    { value: 1, label: "All" },
    { value: 2, label: "Document", type: 2 },
    { value: 3, label: "Ride", type: 1 },
    { value: 4, label: "Delivery", type: 3 }
];
// export const ROUTE_USER_TYPE = {
//     "e-auto-admin": USER_TYPES.ADMIN,
//     "sub-admin": USER_TYPES.SUB_ADMIN,
//     staff: USER_TYPES.STAFF,
//     riders: USER_TYPES.RIDER
// };

export const MERCHANT_DELIVERY_SORT_BY_ARRAY = [
    {
        label: "Delivery Schedule Time",
        type: "reservedEndDateTime",
        value: 1
    }
];
export const USER_TYPES_ARRAY = [
    {
        label: "All",
        value: 1,
        val: "all",
        type: [
            USER_TYPES.ADMIN,
            USER_TYPES.SUB_ADMIN,
            USER_TYPES.STAFF,
            USER_TYPES.PASSENGER,
            USER_TYPES.DRIVER
        ]
    },
    {
        label: "Admin",
        value: 2,
        val: "e-auto-admin",
        type: USER_TYPES.ADMIN
    },
    {
        label: "Sub admin",
        value: 3,
        val: "sub-admin",
        type: USER_TYPES.SUB_ADMIN
    },
    { label: "Staff", value: 4, val: "staff", type: USER_TYPES.STAFF },
    {
        label: "Passenger",
        value: 5,
        val: "passenger",
        type: USER_TYPES.PASSENGER
    },
    { label: "Driver", value: 6, val: "driver", type: USER_TYPES.DRIVER }
];
export const USER_TYPES_ARRAYS = [
    {
        label: "All",
        value: 1,
        val: "all",
        type: [USER_TYPES.ADMIN, USER_TYPES.SUB_ADMIN, USER_TYPES.STAFF]
    },
    {
        label: "Admin",
        value: 2,
        val: "e-auto-admin",
        type: USER_TYPES.ADMIN
    },
    {
        label: "Sub Admin",
        value: 3,
        val: "sub-admin",
        type: USER_TYPES.SUB_ADMIN
    },
    { label: "Staff", value: 4, val: "staff", type: USER_TYPES.STAFF }
];

export const DRIVER_TYPE = {
    W2_DRIVER: 1,
    CONTRACT_DRIVER: 2
};
export const RATING_FROM = {
    RIDE: 1,
    DELIVERY: 2
};
export const DECIMAL_POINT = 2;
export const INDIAN_CURRENCYS = "$";

export const DISTANCE_UNIT = {
    KM: 1,
    MILES: 2
};
export const FARE_STATUS = {
    CURRENT: 1,
    NEXT: 2,
    PAST: 3
};

export const FILE_TYPES = {
    pdf: ["application/pdf"],
    image: ["image/jpeg", "image/png", "image/jpg"],
    json: ["application/json"],
    xlsx: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
};
export const FILE_TYPES_PROFILE = {
    image: ["image/jpeg", "image/png", "image/jpg"],
    json: ["application/json"],
    xlsx: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
};

export const GENDER_TYPE = {
    1: "Male",
    2: "Female"
};

export const MASTER_CODES = {
    MANUFACTURER: "MANUFACTURER",
    LOCK_MANUFACTURER: "LOCK_MANUFACTURER",
    CHARGING_PLUG: "CHARGING_PLUG",
    CHARGING_POWER: "CHARGING_POWER"
};

export const ONLY_NUMBER_REQ_EXP = "^[0-9]*$";
export const DECIMAL_NUMBER_REG_EXP = "^[0-9]+(.[0-9]{1,2})?$";

export const DAYS = {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat"
};

export const OVERFLOW_COUNT = 15000;
export const LOCATION_TYPE = {
    COUNTRY: 1,
    STATE: 2,
    CITY: 3
};

export const SETTING_TYPE = {
    RIDE: 1,
    NOTIFICATION: 2
};
export const VERSION_PLATFORM = {
    ANDROID: 1,
    IPHONE: 2
};
export const VEHICLE_TYPE = {
    AUTO: 1,
    CAR: 2,
    E_SCOOTER: 3
};

export const VEHICLE_IMAGE_SIDE = {
    FRONT: 1,
    BACK: 2,
    LEFT: 3,
    RIGHT: 4
};
export const FUEL_TYPE = {
    DIESEL: 1,
    PETROL: 2,
    ELECTRIC: 3,
    CNG: 4
};
export const CUSTOMER_TYPE = {
    CUSTOMER: 1,
    CONTACT: 2,
    MEMBERS: 3
};
export const MASTER_DATA = [
    "VEHICLE_CATEGORIES",
    "VEHICLE_BRAND",
    "VEHICLE_TYPE",
    "PARCEL_STATUS",
    "DISPUTE_STATUS",
];
export const RIDE_TYPE = {
    NORMAL: 1,
    SHARING: 2,
    RECURRING: 3,
    SCHEDULE: 4,
    MANUAL: 5,
    DELIVERY: 6,
    MERCHANT_DELIVERY: 9,
    MICRO_DELIVERY: 13
};
export const MERCHANT_USER = 7;

export const RIDE_BY = {
    RIDE: 1,
    DELIVERY: 2,
    MERCHANT: 3,
    MICRO_DELIVERY: 4
};
export const RIDE_STATUS = {
    ONGOING: 1,
    COMPLETED: 2,
    CANCELLED: 5,
    UPCOMING: 7,
    ACCEPTED: 8,
    ARRIVED_PICKUP_LOCATION: 9
};
export const RIDE_PAYMENT_STATUS = {
    PAID: 1,
    UNPAID: 2
};

export const TRANSACTION_LOG_CHARGE_TYPE = {
    CARD_VERIFY: 1,
    RIDE_COMPLETED: 2,
    FAILED_PAYMENT_CHARGE: 4,
    REFUND: 5,
    WALLET_CREDIT: 6,
    WALLET_DEBIT: 7,
    RIDE_DEPOSIT: 8,
    PAID: 9,
    PENDING: 10,
    CAPTURED: 11,
    EXPIRED: 12,
    CANCELED: 4,
    SUCCEEDED: 9,
    MEMBERSHIP: 13
};

export const RIDE_SERVICE_TYPE = {
    R: 1,
    D: 2,
    GO: 3
};
export const CASH_TYPE = {
    PENDING: 1,
    SUBMITTED: 2
};
export const CONTACT_US = {
    OPEN: 1,
    CLOSE: 2,
};
export const DELIVERY_TYPE = {
    MANUAL: 5,
    INSTANT: 6,
    RECURRING: 7,
    SCHEDULE: 8
};

export const MERCHANT_DELIVERY_TYPE = {
    SINGLE: 1,
    MULTIPLE: 2
};

export const DISPUTE_BY = {
    RIDE: 1,
    DELIVERY: 2,
    MULTI_DELIVERY: 3,
    MICRO_DELIVERY: 4
};
export const DISPUTE_TYPE = {
    CUSTOMER: 1,
    DRIVER: 2
};

export const DRIVER_PRESENT = 1;
export const DRIVER_ABSENT = 2;

//---------------------------------filter constants start-----------------------------------------------//
export const FILTER_BY_PASSENGER_TYPE = [
    // {
    //     label: 'All',
    //     value: 0
    // },
    {
        label: "General",
        value: 1,
        type: PASSENGER_TYPES.GENERAL,
        color: "#2DB7F5"
    },
    { label: "Vet", value: 2, type: PASSENGER_TYPES.VET, color: "#87D068" },
    {
        label: "Elderly",
        value: 3,
        type: PASSENGER_TYPES.ELDERLY,
        color: "#F50"
    },
    {
        label: "Differently Able",
        value: 4,
        type: PASSENGER_TYPES.DIFFERENTLY_ABLE,
        color: "#000"
    }
];

export const FILTER_BY_FARE_RIDE_TYPE = [
    { label: "All", value: 0 },
    { label: "NR", value: 1, type: RIDE_TYPE.NORMAL },
    { label: "RS", value: 2, type: RIDE_TYPE.SHARING },
    { label: "ND", value: 5, type: RIDE_TYPE.DELIVERY }
];

export const FILTER_BY_MEMBERSHIP_STATUS = [
    { label: "All", value: 0, type: 0 },
    { label: "Current", value: 1, type: MEMBERSHIP_STATUS.CURRENT },
    { label: "Upgrade", value: 2, type: MEMBERSHIP_STATUS.UPGRADE },
    { label: "Degrade", value: 3, type: MEMBERSHIP_STATUS.DEGRADE },
    { label: "Cancelled", value: 4, type: MEMBERSHIP_STATUS.CANCELED },
    { label: "Expired", value: 5, type: MEMBERSHIP_STATUS.EXPIRED }
];
export const FILTER_BY_DRIVER_TYPE = [
    { label: "All", value: 0 },
    { label: "W2 Driver", value: 1, type: DRIVER_TYPE.W2_DRIVER },
    { label: "Contract Driver", value: 2, type: DRIVER_TYPE.CONTRACT_DRIVER }
];
export const FILTER_BY_DOCUMENT_STATUS = [
    { label: "All", value: 0 },
    {
        label: "Pending",
        value: 1,
        type: DOCUMENT_VERIFICATION_STATUS.PENDING
    },
    {
        label: "Verified",
        value: 2,
        type: DOCUMENT_VERIFICATION_STATUS.APPROVED
    },
    {
        label: "Rejected",
        value: 3,
        type: DOCUMENT_VERIFICATION_STATUS.REJECTED
    }
];

export const FOS_SORT_BY_ARRAY = [
    { label: "Name", type: "name", value: 2 },
    { label: "None", value: 1 },
];
export const DISPUTE_PRIORITY = [
    { value: 0, label: "All" },
    { value: 1, label: "URGENT", type: 1 },
    { value: 2, label: "HIGH", type: 2 },
    { value: 3, label: "MEDIUM", type: 3 },
    { value: 4, label: "LOW", type: 4 }
];
export const DISPUTE_PRIORITYS = [
    { value: 1, label: "URGENT", type: 1 },
    { value: 2, label: "HIGH", type: 2 },
    { value: 3, label: "MEDIUM", type: 3 },
    { value: 4, label: "LOW", type: 4 }
];
export const FILTER_BY_RIDE_BY = [
    { label: "All", value: 0 },
    { label: "Ride", value: 1, type: DISPUTE_BY.RIDE },
    { label: "Delivery", value: 2, type: DISPUTE_BY.DELIVERY }
];

export const COMMISSION_DETAILS_SORT_ARRAY = [
    { label: "None", value: 1 },
    { label: "Name", type: "name", value: 2 },
    { label: "Date", type: "endDateTime", value: 3 }
];
export const FILTER_BY_CASH_STATUS = [
    { label: "PENDING", value: 1, type: 1 },
    { label: "SUBMITTED", value: 2, type: 2 }
];
export const FILTER_BY_CONTACT_US_STATUS = [
    { label: "OPEN", value: 1, type: 1 },
    { label: "CLOSED", value: 2, type: 2 }
]
export const FILTER_BY_RIDE_TYPE = [
    { label: "All", value: 0 },
    { label: "NR", value: 1, type: RIDE_TYPE.NORMAL },
    { label: "RS", value: 2, type: RIDE_TYPE.SHARING },
    // { label: "R", value: 5, type: 1 }
];

export const FILTER_BY_SERVICE_TYPE = [
    { label: "All", value: 4 },
    { label: "R", value: 1, type: 1 },
    { label: "D", value: 2, type: 2 },
    { label: "GO", value: 3, type: 3 }
];
export const FILTER_BY_CUSTOMER_TYPE = [
    { label: "All", value: 0 },
    { label: "Contact", value: 1, type: CUSTOMER_TYPE.CONTACT },
    { label: "Customer", value: 2, type: CUSTOMER_TYPE.CUSTOMER },
    { label: "Member", value: 3, type: CUSTOMER_TYPE.MEMBERS }
];
export const DAYS_FULL_NAME = {
    1: "MONDAY",
    2: "TUESDAY",
    3: "WEDNESDAY",
    4: "THURSDAY",
    5: "FRIDAY",
    6: "SATURDAY",
    7: "SUNDAY",
}
export const DAYS_FULL_INDEX = {
    SUNDAY: 1,
    MONDAY: 2,
    TUESDAY: 3,
    WEDNESDAY: 4,
    THURSDAY: 5,
    FRIDAY: 6,
    SATURDAY: 7,
}
export const SORT_BY_ARRAY_TIER = [
    {
        label: "Tier Name",
        key: "name",
        value: 1,
        type: "name"
    }
];
export const SORT_BY_CREATED_AT = [
    {
        label: "Date Added",
        key: "createdAt",
        value: 1,
        type: "createdAt"
    }
];

export const FILTER_BY_IS_VERIFY = [
    { label: "All", value: 1 },
    { label: "Verified", value: 2, type: VERIFY_TYPES.VERIFIED },
    { label: "Unverified", value: 3, type: VERIFY_TYPES.UNVERIFIED }
];

export const FILTER_BY_ACTIVE = [
    { label: "All", value: 1 },
    { label: "Active", value: 2, type: STATUS_TYPES.ACTIVE },
    { label: "Deactive", value: 3, type: STATUS_TYPES.DEACTIVE }
];
export const SORT_BY_ARRAY = [
    { label: "None", value: 1 },
    { label: "Vehicle Id", type: "registerId", value: 5 }
];

export const FILTER_BY_PAYMENT_TYPE = [
    { label: "All", value: 0 },
    { label: "ONLINE", value: 1, type: PAYMENT_TYPE.ONLINE },
    // { label: "CASH", value: 2, type: PAYMENT_TYPE.CASH },
    { label: "WALLET", value: 3, type: PAYMENT_TYPE.WALLET }
];
export const FILTER_BY_PAYMENT_PAID_STATUS = [
    { label: "All", value: 0 },
    { label: "Paid", value: 1, type: PAYMENT_STATUS.PAID },
    { label: "UnPaid", value: 2, type: PAYMENT_STATUS.UNPAID },
    { label: "Failed", value: 3, type: PAYMENT_STATUS.FAILED },
    { label: "Refund", value: 4, type: PAYMENT_STATUS.REFUND },
    { label: "Expired", value: 5, type: PAYMENT_STATUS.EXPIRED }
];

export const FILTER_BY_RIDE_BY_OPTION = [
    { label: "Ride", value: 1, type: 1 },
    { label: "Delivery", value: 2, type: 2 }
];
export const FILTER_BY_DELIVERY_TYPE = [
    { label: "All", value: 0 },
    { label: "Schedule", value: 8, type: DELIVERY_TYPE.SCHEDULE },
    {
        label: "Recurring",
        value: 7,
        type: DELIVERY_TYPE.RECURRING
    },
    {
        label: "Instant",
        value: 6,
        type: DELIVERY_TYPE.INSTANT
    },
    // {
    //     label: "Manual",
    //     value: 5,
    //     type: DELIVERY_TYPE.MANUAL
    // }
];
export const FILTER_BY_DELIVERY_TYPE_MERCHANT = [
    {
        label: "Single Delivery",
        value: 6,
        type: RIDE_TYPE.DELIVERY
    },
    {
        label: "Multiple Delivery",
        value: 9,
        type: RIDE_TYPE.MERCHANT_DELIVERY
    }
];
export const FILTER_BY_MERCHANT_DELIVERY_TYPE = [
    { label: "All", value: 0 },
    { label: "Single", value: 1, type: MERCHANT_DELIVERY_TYPE.SINGLE },
    {
        label: "Multiple",
        value: 2,
        type: MERCHANT_DELIVERY_TYPE.MULTIPLE
    }
];
export const FILTER_BY_DELIVERY_TYPE_CUSTOMER = [
    { label: "All", value: 0 },
    { label: "Schedule", value: 8, type: DELIVERY_TYPE.SCHEDULE },
    {
        label: "Recurring",
        value: 7,
        type: DELIVERY_TYPE.RECURRING
    },
    {
        label: "Instant",
        value: 6,
        type: DELIVERY_TYPE.INSTANT
    }
];
export const MICRO_DELIVERY_TYPE = {
    MICRO_MOBILITY: 13,
    ROAD_SIDE_ASSISTANTS: 14
}
export const FILTER_BY_MICRO_DELIVERY_TYPE_CUSTOMER = [
    { label: "All", value: 0 },
    { label: "Micro Mobility", value: 13, type: MICRO_DELIVERY_TYPE.MICRO_MOBILITY },
    {
        label: "Roadside Assistants Delivery",
        value: 14,
        type: MICRO_DELIVERY_TYPE.ROAD_SIDE_ASSISTANTS
    },
];

export const FILTER_BY_MICRO_VEHICLE_TYPE_CUSTOMER = [
    { label: "All", value: 0 },
    { label: "E-Scooter", value: 1, type: MICRO_VEHICLE_TYPE.SCOOTER },
    {
        label: "E-Bike",
        value: 2,
        type: MICRO_VEHICLE_TYPE.EBIKE
    },
];
export const SORT_BY_ARRAY_USER = [
    {
        label: "Name",
        key: "name",
        value: 1,
        type: "name"
    },
    {
        label: "Sign Up Date",
        key: "createdAt",
        value: 2,
        type: "createdAt"
    }
];
export const RIDE_DETAIL_SORT_BY_ARRAY = [
    { label: "Ride Start Time", type: "startDateTime", value: 1 }
];
export const SORT_BY_ARRAY_CUSTOMER = [
    {
        label: "Name",
        key: "name",
        value: 1,
        type: "name"
    },
    {
        label: "Registration Date",
        key: "createdAt",
        value: 2,
        type: "createdAt"
    }
];
export const SORT_BY_CASH_COLLECTION = [
    {
        label: "Driver Name",
        key: "name",
        value: 1,
        type: "userData.firstName"
    },
    {
        label: "Assigned FOS",
        key: "fos",
        value: 2,
        type: "fosData.name"
    }
];
export const SORT_BY_CUSTOMER_SERVICE = [
    {
        label: "Customer Name",
        key: "name",
        value: 1,
        type: "userId.name"
    }
];
export const FILTER_BY_DAILY_RIDE_STATUS = [
    {
        label: "Completed",
        value: RIDE_STATUS.COMPLETED,
        type: RIDE_STATUS.COMPLETED
    },
    {
        label: "Upcoming",
        value: RIDE_STATUS.UPCOMING,
        type: RIDE_STATUS.UPCOMING
    },
    {
        label: "Ongoing",
        value: RIDE_STATUS.ONGOING,
        type: RIDE_STATUS.ONGOING
    }
];
export const ATTENDANCE_SORT_BY_ARRAY = [
    { label: "None", value: 1 },
    { label: "Name", type: "name", value: 2 },
    { label: "Total Time", type: "totalLogTimeOfTheDay", value: 3 }
];
export const TIME_SHEET_SORT_BY_ARRAY = [
    { label: "None", value: 1 },
    { label: "Name", type: "name", value: 2, asc: true },
    { label: "Avg. Total Time", type: "data.avgLogTime", value: 3 }
];
export const TIME_SHEET_DETAIL_SORT_BY_ARRAY = [
    { label: "None", value: 1 },
    { label: "Total Break Time", type: "totalBreakTimeOfTheDay", value: 2 },
    { label: "Total Log Time", type: "totalLogTimeOfTheDay", value: 3 }
];
export const START_DATE_SORT_BY_ARRAY = [
    { label: "Start Date", type: "startDate", value: 1 }
];
export const DRIVER_NAME_SORT_ARRAY = [
    { label: "Driver Name", type: "name", value: 1 }
];
export const SORT_BY_DAILY_REPORT = [
    { label: "Driver Name", type: "name", value: 1 },
    { label: "Date", type: "endDateTime", value: 2 }
];
export const RIDE_SUMMARY_SORT_BY_ARRAY = [
    { label: "None", value: 1 },
    {
        label: "Total Fare Amount",
        type: "rideSummary.totalFairSum",
        value: 2
    },
    {
        label: "Total Rides",
        type: "rideSummary.totalRidesSum",
        value: 3
    },
    {
        label: "Total Passengers",
        type: "rideSummary.totalPassengerSum",
        value: 4
    }
];
export const SORT_BY_END_DATE = [
    {
        label: "Date",
        type: "_id.endDateTime",
        value: 1
    }
];
export const SORT_BY_ARRAY_PAYMENT_DATE = [
    {
        label: "Payment Date",
        key: "paymentDate",
        value: 1,
        type: "paymentDate"
    }
];
export const FILTER_BY_RATING_STAR = [
    {
        label: "All",
        value: 0
    },
    { label: "1 Star", value: 1, type: 1 },
    { label: "2 Star", value: 2, type: 2 },
    { label: "3 Star", value: 3, type: 3 },
    { label: "4 Star", value: 4, type: 4 },
    { label: "5 Star", value: 5, type: 5 }
];
export const FILTER_BY_AVG_RATING_STAR = [
    {
        label: "All",
        value: 0
    },
    { label: "1 Star", value: 1, type: 1 },
    { label: "2 Star", value: 2, type: 2 },
    { label: "3 Star", value: 3, type: 3 },
    { label: "4 Star", value: 4, type: 4 },
    { label: "5 Star", value: 5, type: 5 }
];
export const SORT_BY_ARRAY_RATING = [
    { label: "None", value: 0 },
    ,
    {
        label: "Rating Star",
        key: "rating",
        value: 1,
        type: "rating"
    }
];
export const SORT_BY_DAILY_REPORT_REJECTED = [
    { label: "Date", type: "endDateTime", value: 2 }
];

export const DELIVERY_SORT_BY_ARRAY = [
    { label: "Delivery Start Time", type: "startDateTime", value: 1 }
];
export const DEFAULT_PAYMENT_METHOD = [
    "STRIPE",
    "ALLO",
    "PAGCERTO",
    "HYPERPAY",
    "PROXYPAY",
    "NOQOODY",
    "AUTHORIZENET"
];

export const USER_LOGIN_TYPES = {
    EMAIL: 1,
    MOBILE: 2
};

export const COLLECTION_STATUS = {
    PENDING: 1,
    SUBMITTED: 2
};
export const CONTACT_US_STATUS = {
    OPEN: 1,
    CLOSE: 2
};
//---------------------------------filter constants End-----------------------------------------------//

export const REGEX = {
    URL: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
};

export const pageDetails = {
    signin: "Login | Volt-Mobility",
    master: "Master | Volt-Mobility",
    "sub-master": "Data Bnk | Volt-Mobility",
    dashboard: "DashBoard | Volt-Mobility",
    drivers: "Drivers | Volt-Mobility",
    users: "Users | Volt-Mobility",
    customer: "Customers | Volt-Mobility",
    vehicle: "Vehicle | Volt-Mobility",
    "member-ship": "Membership | Volt-Mobility",
    "daily-reports": 'Daily Reports | Volt-Mobility',
    attendance: "Attendance | Volt-Mobility",
    dispute: "Dispute| Volt-Mobility",
    "ride-summary": "Ride Summary | Volt-Mobility",
    "ride-detail": "Ride Details | Volt-Mobility",
    "rejected-rides": "Rejected Rides | Volt-Mobility",
    "fare-config": "Fare Configuration | Volt-Mobility",
    "time-sheet-summary": "TimeSheet Summary | Volt-Mobility",
    "time-sheet-detail": "TimeSheet Details | Volt-Mobility",
    "driver-commission": "Commission Summary | Volt-Mobility",
    "commission-summary": "Commission Summary | Volt-Mobility",
    "commission-details": "Commission Details | Volt-Mobility",
    promocode: "Promotions | Volt-Mobility",
    tier: "Tier | Volt-Mobility",
    fos: "FOS | Volt-Mobility",
    "fare-collection": "Fare Collection | Volt-Mobility",
    "cash-collection": "Cash Collection | Volt-Mobility",
    "transaction-details": "Transaction Details | Volt-Mobility",
    "referral-list": "Referral List | Volt-Mobility",
    ratings: "Ratings | Volt-Mobility",
    version: "Version | Volt-Mobility",
    notification: "Notifications | Volt-Mobility",
    "static-page": "Static Page | Volt-Mobility",
    setting: "Settings | Volt-Mobility",
    profile: "Profile | Volt-Mobility",
    "time-shift": "Time Shift | Volt-Mobility"
};
