import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
const _ = require("lodash");

class CustomRoute extends Component {
    render() {
        const {
            auth,
            path,
            component,
            pageId,
            exact,
            insert,
            update,
            deleted,
            view
        } = this.props;
        if (!auth || !auth.authUser) {
            return true;
        }
        let pagePermission = auth.authUser.accessPermission;
        let indexes = _.findIndex(pagePermission, { module: pageId });
        let hasPermission =
            pagePermission[indexes] &&
            pagePermission[indexes].permissions &&
            pagePermission[indexes].permissions.list;
        if (insert) {
            hasPermission =
                hasPermission &&
                pagePermission[indexes] &&
                pagePermission[indexes].permissions &&
                pagePermission[indexes].permissions.insert;
        }
        if (view) {
            hasPermission =
                hasPermission &&
                pagePermission[indexes] &&
                pagePermission[indexes].permissions &&
                pagePermission[indexes].permissions.view;
        }
        if (update) {
            hasPermission =
                hasPermission &&
                pagePermission[indexes] &&
                pagePermission[indexes].permissions &&
                pagePermission[indexes].permissions.update;
        }
        if (deleted) {
            hasPermission =
                hasPermission &&
                pagePermission[indexes] &&
                pagePermission[indexes].permissions &&
                pagePermission[indexes].permissions.delete;
        }
        return hasPermission ? (
            <Route {...exact} path={path} component={component} />
        ) : (
            <Redirect from="*" to="/404" />
        );
    }
}
const mapStateToProps = ({ auth }) => {
    return { auth };
};

export default connect(mapStateToProps)(CustomRoute);
