import { PAGE_PERMISSION } from "./PagePermission";
export const PROJECT_ROUTE = "volt-mobility";

export const MENU = [
    {
        name: "Dashboard",
        id: PAGE_PERMISSION.VOLT_DASHBOARD,
        path: `/${PROJECT_ROUTE}/dashboard`,
        svg: "Dashboard",
        icon: "desktop"
    },
    {
        name: `Daily Reports`,
        id: PAGE_PERMISSION.VOLT_DAILY_REPORT,
        path: `/${PROJECT_ROUTE}/daily-reports`,
        svg: `DailyReports`,
        icon: `desktop`
    },

    {
        name: `Drivers`,
        id: PAGE_PERMISSION.VOLT_DRIVERS,
        path: `/${PROJECT_ROUTE}/drivers`,
        svg: `Drivers`,
        icon: `desktop`
    },

    {
        name: `Customers`,
        id: PAGE_PERMISSION.VOLT_CUSTOMERS,
        path: `/${PROJECT_ROUTE}/customer`,
        svg: `Customer`,
        icon: `desktop`
    },
    {
        name: `Users`,
        id: PAGE_PERMISSION.VOLT_USERS,
        path: `/${PROJECT_ROUTE}/users`,
        svg: `Users`,
        icon: `Users`
    },

    {
        name: `Vehicle`,
        id: PAGE_PERMISSION.VOLT_VEHICLES,
        path: `/${PROJECT_ROUTE}/vehicle`,
        svg: `Vehicle`,
        icon: `desktop`
    },
    {
        name: `Membership`,
        id: PAGE_PERMISSION.VOLT_MEMBER_SHIP,
        path: `/${PROJECT_ROUTE}/member-ship`,
        icon: `desktop`,
        svg: `MemberShip`
    },
    {
        name: "Ride Summary",
        path: `/${PROJECT_ROUTE}/ride-summary`,
        id: PAGE_PERMISSION.VOLT_RIDE_SUMMARY,
        icon: "desktop",
        svg: "RideSummary"
    },
    {
        name: "Ride Detail",
        path: `/${PROJECT_ROUTE}/ride-detail`,
        id: PAGE_PERMISSION.VOLT_RIDE_DETAILS,
        icon: "desktop",
        svg: "RideDetail"
    },
    {
        name: "Delivery History",
        path: `/${PROJECT_ROUTE}/delivery-history`,
        id: PAGE_PERMISSION.VOLT_DELIVERY_HISTORY,
        icon: "desktop",
        svg: "DeliveryHistory"
    },
    {
        name: "Multiple Delivery",
        path: `/${PROJECT_ROUTE}/merchant-delivery`,
        id: 36,
        icon: "desktop",
        svg: "MerchantDelivery"
    },
    {
        name: "Micro Mobility Delivery History",
        path: `/${PROJECT_ROUTE}/micro-mobility-delivery-history`,
        id: PAGE_PERMISSION.MICRO_MOBILITY_DELIVERY_HISTORY,
        icon: "desktop",
        svg: "DeliveryHistory"
    },
    {
        name: "Recurring History",
        path: `/${PROJECT_ROUTE}/recurring-history`,
        id: PAGE_PERMISSION.VOLT_RECURRING_HISTORY,
        icon: "desktop",
        svg: "RecurringHistory"
    },
    {
        name: "Schedule History",
        path: `/${PROJECT_ROUTE}/schedule-history`,
        id: PAGE_PERMISSION.VOLT_SCHEDULE_HISTORY,
        icon: "desktop",
        svg: "ScheduleHistory"
    },
    {
        name: "Rejected Rides/Deliveries",
        path: `/${PROJECT_ROUTE}/rejected-rides`,
        id: PAGE_PERMISSION.VOLT_REJECTED_RIDES,
        icon: "desktop",
        svg: "RejectedRides"
    },
    {
        name: "Attendance",
        path: `/${PROJECT_ROUTE}/attendance`,
        id: PAGE_PERMISSION.VOLT_ATTENDANCE,
        icon: "desktop",
        svg: "Attendance"
    },
    {
        name: "TimeSheet Summary",
        path: `/${PROJECT_ROUTE}/time-sheet-summary`,
        id: PAGE_PERMISSION.VOLT_TIME_SHEET_SUMMARY,
        icon: "desktop",
        svg: "TimeSheet"
    },

    {
        name: "TimeSheet Detail",
        path: `/${PROJECT_ROUTE}/time-sheet-detail`,
        id: PAGE_PERMISSION.VOLT_TIME_SHEET_DETAILS,
        icon: "desktop",
        svg: "TimeSheetDetail"
    },

    {
        name: "Fare Collection",
        path: `/${PROJECT_ROUTE}/fare-collection`,
        id: PAGE_PERMISSION.VOLT_FARE_COLLECTION,
        icon: "desktop",
        svg: "FareCollection"
    },
    {
        name: "Cash Collection",
        path: `/${PROJECT_ROUTE}/cash-collection`,
        id: PAGE_PERMISSION.VOLT_CASH_COLLECTION,
        icon: "desktop",
        svg: "CashCollection"
    },
    {
        name: "Delivery Collection",
        path: `/${PROJECT_ROUTE}/delivery-collection`,
        id: PAGE_PERMISSION.VOLT_DELIVERY_COLLECTION,
        icon: "desktop",
        svg: "DeliveryCollection"
    },
    {
        name: "Commission Summary",
        path: `/${PROJECT_ROUTE}/commission-summary`,
        id: PAGE_PERMISSION.VOLT_COMMISSION_SUMMARY,
        icon: "desktop",
        svg: "CommissionSummary"
    },
    {
        name: "Commission Details",
        path: `/${PROJECT_ROUTE}/commission-details`,
        id: PAGE_PERMISSION.VOLT_COMMISSION_DETAILS,
        icon: "desktop",
        svg: "CommissionDetail"
    },
    {
        name: "Dispute",
        path: `/${PROJECT_ROUTE}/dispute`,
        id: PAGE_PERMISSION.VOLT_DISPUTE,
        icon: "desktop",
        svg: "Dispute"
    },
    {
        name: "Rating",
        path: `/${PROJECT_ROUTE}/ratings`,
        id: PAGE_PERMISSION.VOLT_RATINGS,
        icon: "desktop",
        svg: "Rating"
    },
    {
        name: "Mobility",
        path: `https://micromobility.reevaas.com/e-scooter/dashboard`,
        id: PAGE_PERMISSION.VOLT_MOBILITY,
        icon: "desktop",
        svg:"Scooter",
    }
];
export const SETUP_MODULES = [
    {
        name: `Master`,
        path: `/${PROJECT_ROUTE}/master/main-master`,
        componentName: `Master`,
        id: PAGE_PERMISSION.VOLT_MASTER,
        icon: `desktop`
    },
    {
        name: `Sub-Master`,
        path: `/${PROJECT_ROUTE}/master/sub-master`,
        componentName: `SubMaster`,
        id: PAGE_PERMISSION.VOLT_SUB_MASTER,
        icon: `desktop`
    },

    {
        name: "Fare Configuration",
        path: `/${PROJECT_ROUTE}/fare-config`,
        id: PAGE_PERMISSION.VOLT_FARE_CONFIG,
        svg: "Price",
        icon: "Price"
    },
    {
        name: "Tier",
        path: `/${PROJECT_ROUTE}/tier`,
        id: PAGE_PERMISSION.VOLT_TIER,
        icon: "desktop",
        svg: "Tier"
    },
    {
        name: "FOS",
        path: `/${PROJECT_ROUTE}/fos`,
        id: PAGE_PERMISSION.VOLT_FOS,
        icon: "desktop",
        svg: "FOS"
    },
    {
        name: "Time-Shift",
        path: `/${PROJECT_ROUTE}/time-shift`,
        id: PAGE_PERMISSION.VOLT_TIME_SHIFT,
        icon: "desktop"
    },
    {
        name: "Promotions",
        path: `/${PROJECT_ROUTE}/promo-code`,
        id: PAGE_PERMISSION.VOLT_PROMO_CODE,
        icon: "desktop"
    },
    {
        name: "Transaction Details",
        path: `/${PROJECT_ROUTE}/transaction-details`,
        id: PAGE_PERMISSION.VOLT_TRANSACTION_DETAILS,
        icon: "desktop"
    },
    // {
    //     name: "Referral List",
    //     path: `/${PROJECT_ROUTE}/referral-list`,
    //     id: PAGE_PERMISSION.VOLT_REFERRAL,
    //     icon: "desktop"
    // },
    {
        name: "Notifications",
        path: `/${PROJECT_ROUTE}/notification`,
        id: PAGE_PERMISSION.VOLT_NOTIFICATION,
        icon: "desktop"
    },
    {
        name: "Static Page",
        path: `/${PROJECT_ROUTE}/static-page`,
        svg: "StaticPage",
        id: PAGE_PERMISSION.VOLT_STATIC_PAGE,
        icon: "StaticPage"
    },
    {
        name: `Version`,
        path: `/${PROJECT_ROUTE}/version`,
        id: PAGE_PERMISSION.VOLT_VERSION,
        icon: `desktop`,
        svg: `Version`
    },
    {
        name: `Roles`,
        path: `/${PROJECT_ROUTE}/roles`,
        id: PAGE_PERMISSION.VOLT_ROLES,
        icon: `desktop`
    },
    {
        name: "Customer Service",
        path: `/${PROJECT_ROUTE}/customer-service`,
        id: PAGE_PERMISSION.VOLT_FARE_CONFIG,
        svg: "desktop",
        icon: "desktop"
    },
    {
        name: `Settings`,
        path: `/${PROJECT_ROUTE}/setting`,
        id: PAGE_PERMISSION.VOLT_SETTINGS,
        icon: `desktop`
    }
];

//array object of name & id pair of page
// const arr = [...MENU, ...SETUP_MODULES];
// let obj = {};
// arr.forEach(function(item) {
//     var key = item.name.replace(/\s/g, ``).toLowerCase();
//     obj[key] = item.id;
// });
// export const PAGE_PERMISSION = obj;
