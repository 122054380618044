import React from "react";
import { pageDetails } from "../constants/Common";
import { Helmet } from "react-helmet";

class SEO extends React.Component {
    render() {
        let content = pageDetails[this.props.url];

        if (content) {
            return (
                <Helmet>
                    <title>{content}</title>
                </Helmet>
            );
        } else {
            return (
                <Helmet>
                    <title>Volt-Mobility</title>
                </Helmet>
            );
        }
    }
}
export default SEO;
