export const PROJECT_NAME = "Volt-Mobility";
export const BASE_URL = "https://admin.reevaas.com/";
export const BASE_URL_IMAGE = "https://admin.reevaas.com/";
export const BASE_URL_IMAGE_DRIVER = "https://admin.reevaas.com/";
export const BASE_URL_EXCEL = "https://admin.reevaas.com/data/csv/";

export const FILTER_VISIBLE = false;
export const DOCUMENT_VERIFICATION_REQUIRED = true;
export const DEFAULT_BASE_CURRENCY = "$";
export const UNIT_TYPE_ARRAY = [
    { value: 1, label: DEFAULT_BASE_CURRENCY },
    { value: 2, label: "%" }
];
export const DEFAULT_DISTANCE_UNIT = 2;
export const CUSTOM_MODAL_WIDTH = 720;
export const DEFAULT_MAP_CENTER = {
    lat: 39.77705261986147,
    lng: -104.99399588631411
};
export const SOCKET_CONNECTION = true;
export const VEHICLE_TYPES = {
    SCOOTER: 1,
    BICYCLE: 2,
    BUS: 3
};
export const FILTER_BY_VEHICLE_TYPE = [
    {
        label: "All",
        value: 0,
        // type: [VEHICLE_TYPES.SCOOTER, VEHICLE_TYPES.BICYCLE]
        type: [VEHICLE_TYPES.BUS]
    },
    { label: "Scooter", value: 1, type: [VEHICLE_TYPES.SCOOTER] },
    { label: "Bicycle", value: 2, type: [VEHICLE_TYPES.BICYCLE] },
    { label: "BUS", value: 3, type: [VEHICLE_TYPES.BUS] }
];

export const VEHICLE_TYPE_FILTER = [
    { label: "All", value: 0, type: VEHICLE_TYPES.BUS },
    { label: "Scooter", value: 1, type: VEHICLE_TYPES.SCOOTER },
    { label: "BUS", value: 3, type: VEHICLE_TYPES.BUS },
    { label: "Bicycle", value: 2, type: VEHICLE_TYPES.BICYCLE }
];
export const DEFAULT_VEHICLE = FILTER_BY_VEHICLE_TYPE[1].value;
export const DEFAULT_VEHICLE_TYPE = FILTER_BY_VEHICLE_TYPE[3].value;

export const LANGUAGES = [
    // LTR
    { id: "en-US", name: "English" },
    { id: "pt-PT", name: "Portuguese" },
    { id: "es-ES", name: "Spanish" },
    { id: "ko-KR", name: "Korean" },
    // RTL
    { id: "ar-AE", name: "Arabic" }
];
export const LANGUAGES_NAME = {
    "en-US": "English",
    "pt-PT": "Portuguese",
    "es-ES": "Spanish",
    "ar-AE": "Arabic",
    "ko-KR": "Korean"
};
export const DEFAULT_LANGUAGE = "en-US";
export const RTL_LANGUAGE = ["ar-AE"];
