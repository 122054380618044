import React, { Component } from "react";
import "./auto.css";
export default class EAutoLoader extends Component {
    render() {
        return (
            <span className="loader">
                <img
                    src={require("../../assets/images/voltloader.svg")}
                    alt="loader"
                />
            </span>
        );
    }
}
