/* eslint-disable max-lines-per-function */
import { Redirect, Route, Switch } from "react-router-dom";
import React from "react";
import { PAGE_PERMISSION, PROJECT_ROUTE } from "../constants/Common";
import asyncComponent from "util/asyncComponent";
import CustomRoute from "./CustomRoute";

const App = ({ match }) => {
    return (
        <div className="gx-main-content-wrapper">
            <Switch>
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_DASHBOARD}
                    path={`${match.url}${PROJECT_ROUTE}/dashboard`}
                    component={asyncComponent(() => {
                        return import("./Dashboard");
                    })}
                />
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_MASTER}
                    path={`${match.url}${PROJECT_ROUTE}/master/main-master`}
                    component={asyncComponent(() => {
                        return import("./Master");
                    })}
                />
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_SUB_MASTER}
                    path={`${match.url}${PROJECT_ROUTE}/master/sub-master/:id?`}
                    component={asyncComponent(() => {
                        return import("./SubMaster");
                    })}
                />{" "}
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_SETTINGS}
                    path={`${match.url}${PROJECT_ROUTE}/setting`}
                    component={asyncComponent(() => {
                        return import("./RideSetting");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_CUSTOMERS}
                    path={`${match.url}${PROJECT_ROUTE}/customer`}
                    component={asyncComponent(() => {
                        return import("./Customers");
                    })}
                />
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_VERSION}
                    path={`${match.url}${PROJECT_ROUTE}/version`}
                    component={asyncComponent(() => {
                        return import("./Version");
                    })}
                />
                 <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_SCHEDULE_HISTORY}
                    path={`${match.url}${PROJECT_ROUTE}/schedule-history-details`}
                    component={asyncComponent(() => {
                        return import("./ScheduleRide/scheduleDetailList");
                    })}
                />
                 <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_RECURRING_MULTI_HISTORY}
                    path={`${match.url}${PROJECT_ROUTE}/recurring-history-details`}
                    component={asyncComponent(() => {
                        return import("./RecurringDetail/RecurringDetailList");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_VEHICLES}
                    path={`${match.url}${PROJECT_ROUTE}/vehicle`}
                    component={asyncComponent(() => {
                        return import("./Vehicle");
                    })}
                />
                <CustomRoute
                    exact
                    insert
                    update
                    pageId={PAGE_PERMISSION.VOLT_VEHICLES}
                    path={`${match.url}${PROJECT_ROUTE}/vehicle/upsert/:id?`}
                    component={asyncComponent(() => {
                        return import("./Vehicle/upsert");
                    })}
                />
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_TIME_SHIFT}
                    path={`${match.url}${PROJECT_ROUTE}/time-shift/upsert`}
                    component={asyncComponent(() => {
                        return import("./TimeShift");
                    })}
                />
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_TIME_SHIFT}
                    path={`${match.url}${PROJECT_ROUTE}/time-shift`}
                    component={asyncComponent(() => {
                        return import("./TimeShift/calender");
                    })}
                />
                <Route
                    path={`${match.url}${PROJECT_ROUTE}/profile`}
                    component={asyncComponent(() => {
                        return import("./Profile");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_MEMBER_SHIP}
                    path={`${match.url}${PROJECT_ROUTE}/member-ship`}
                    component={asyncComponent(() => {
                        return import("./MemberShipList");
                    })}
                />
                <CustomRoute
                    exact
                    insert
                    update
                    pageId={PAGE_PERMISSION.VOLT_MEMBER_SHIP}
                    path={`${match.url}${PROJECT_ROUTE}/member-ship/upsert/:id?`}
                    component={asyncComponent(() => {
                        return import("./MemberShipList/MemberShipUpsert");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_USERS}
                    path={`${match.url}${PROJECT_ROUTE}/users`}
                    component={asyncComponent(() => {
                        return import("./Users");
                    })}
                />
                <CustomRoute
                    exact
                    insert
                    update
                    pageId={PAGE_PERMISSION.VOLT_USERS}
                    path={`${match.url}${PROJECT_ROUTE}/users/upsert/:id?`}
                    component={asyncComponent(() => {
                        return import("./Users/UserUpsert");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_DRIVERS}
                    path={`${match.url}${PROJECT_ROUTE}/drivers`}
                    component={asyncComponent(() => {
                        return import("./DriverList");
                    })}
                />
                <CustomRoute
                    exact
                    insert
                    update
                    pageId={PAGE_PERMISSION.VOLT_DRIVERS}
                    path={`${match.url}${PROJECT_ROUTE}/drivers/upsert/:id?`}
                    component={asyncComponent(() => {
                        return import("./DriverList/DriverUpsert");
                    })}
                />
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_VEHICLES}
                    path={`${match.url}${PROJECT_ROUTE}/vehicles`}
                    component={asyncComponent(() => {
                        return import("./Vehicle");
                    })}
                />
                {/* <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_ROLES}
                    exact
                    path={`${match.url}${PROJECT_ROUTE}/roles`}
                    component={asyncComponent(() => {
                        return import("./Roles");
                    })}
                /> */}
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_FARE_CONFIG}
                    path={`${match.url}${PROJECT_ROUTE}/fare-config`}
                    component={asyncComponent(() => {
                        return import("./Price");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_CUSTOMER_SERVICE}
                    path={`${match.url}${PROJECT_ROUTE}/customer-service`}
                    component={asyncComponent(() => {
                        return import("./ContactUs");
                    })}
                />
                <CustomRoute
                    exact
                    insert
                    update
                    pageId={PAGE_PERMISSION.VOLT_FARE_CONFIG}
                    path={`${match.url}${PROJECT_ROUTE}/fare-config/upsert/:id?`}
                    component={asyncComponent(() => {
                        return import("./Price/PriceUpsert");
                    })}
                />
                {/* <CustomRoute
                    insert
                    update
                    pageId={PAGE_PERMISSION.VOLT_ROLES}
                    path={`${match.url}${PROJECT_ROUTE}/roles/upsert`}
                    component={asyncComponent(() => {
                        return import("./Roles/upsert");
                    })}
                /> */}
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_MULTI_DELIVERY}
                    path={`${match.url}${PROJECT_ROUTE}/merchant-delivery`}
                    component={asyncComponent(() => {
                        return import("./MerchantDelivery");
                    })}
                />{" "}
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_MULTI_DELIVERY}
                    path={`${match.url}${PROJECT_ROUTE}/merchant-delivery/details`}
                    component={asyncComponent(() => {
                        return import(
                            "./MerchantDelivery/MultipleDeliveryList"
                        );
                    })}
                />{" "}
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_FOS}
                    path={`${match.url}${PROJECT_ROUTE}/fos`}
                    component={asyncComponent(() => {
                        return import("./FOS");
                    })}
                />{" "}
                <CustomRoute
                    insert
                    update
                    pageId={PAGE_PERMISSION.VOLT_FOS}
                    path={`${match.url}${PROJECT_ROUTE}/fos/upsert/:id?`}
                    component={asyncComponent(() => {
                        return import("./FOS/FosUpsert");
                    })}
                />{" "}
                {/* <CustomRoute
                    insert
                    update
                    pageId={PAGE_PERMISSION.VOLT_FOS}
                    path={`${match.url}${PROJECT_ROUTE}/fos/time-shift/:id?`}
                    component={asyncComponent(() => {
                        return import("./FOS/TimeShiftTbl");
                    })}
                />{" "} */}
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_TIER}
                    path={`${match.url}${PROJECT_ROUTE}/tier`}
                    component={asyncComponent(() => {
                        return import("./Tier");
                    })}
                />{" "}
                <CustomRoute
                    insert
                    update
                    pageId={PAGE_PERMISSION.VOLT_TIER}
                    path={`${match.url}${PROJECT_ROUTE}/tier/upsert/:id?`}
                    component={asyncComponent(() => {
                        return import("./Tier/TierUpsert");
                    })}
                />{" "}
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_RIDE_SUMMARY}
                    path={`${match.url}${PROJECT_ROUTE}/ride-summary`}
                    component={asyncComponent(() => {
                        return import("./RideSummary");
                    })}
                />{" "}
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_RIDE_DETAILS}
                    path={`${match.url}${PROJECT_ROUTE}/ride-detail`}
                    component={asyncComponent(() => {
                        return import("./RideDetails");
                    })}
                />{" "}
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_DELIVERY_HISTORY}
                    path={`${match.url}${PROJECT_ROUTE}/delivery-history`}
                    component={asyncComponent(() => {
                        return import("./DeliveryHistory");
                    })}
                />{" "}
                <CustomRoute
                    pageId={PAGE_PERMISSION.MICRO_MOBILITY_DELIVERY_HISTORY}
                    path={`${match.url}${PROJECT_ROUTE}/micro-mobility-delivery-history`}
                    component={asyncComponent(() => {
                        return import("./MicroDeliveryHistory");
                    })}
                />{" "}
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_REJECTED_RIDES}
                    path={`${match.url}${PROJECT_ROUTE}/rejected-rides`}
                    component={asyncComponent(() => {
                        return import("./RejectedRides");
                    })}
                />{" "}
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_FARE_COLLECTION}
                    path={`${match.url}${PROJECT_ROUTE}/fare-collection`}
                    component={asyncComponent(() => {
                        return import("./FareCollection");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_ATTENDANCE}
                    path={`${match.url}${PROJECT_ROUTE}/attendance`}
                    component={asyncComponent(() => {
                        return import("./Attendance");
                    })}
                />
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_TIME_SHEET_SUMMARY}
                    path={`${match.url}${PROJECT_ROUTE}/time-sheet-summary`}
                    component={asyncComponent(() => {
                        return import("./TimeSheetSummary");
                    })}
                />
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_TIME_SHEET_DETAILS}
                    path={`${match.url}${PROJECT_ROUTE}/time-sheet-detail`}
                    component={asyncComponent(() => {
                        return import("./TimeSheetDetails");
                    })}
                />
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_SCHEDULE_HISTORY}
                    path={`${match.url}${PROJECT_ROUTE}/schedule-history`}
                    component={asyncComponent(() => {
                        return import("./ScheduleRide");
                    })}
                />
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_RECURRING_HISTORY}
                    path={`${match.url}${PROJECT_ROUTE}/recurring-history`}
                    component={asyncComponent(() => {
                        return import("./RecurringDetail");
                    })}
                />
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_COMMISSION_SUMMARY}
                    path={`${match.url}${PROJECT_ROUTE}/commission-summary`}
                    component={asyncComponent(() => {
                        return import("./CommissionSummary");
                    })}
                />
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_COMMISSION_DETAILS}
                    path={`${match.url}${PROJECT_ROUTE}/commission-details`}
                    component={asyncComponent(() => {
                        return import("./DriverCommissionDetails");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_PROMO_CODE}
                    path={`${match.url}${PROJECT_ROUTE}/promo-code`}
                    component={asyncComponent(() => {
                        return import("./PromoCode");
                    })}
                />
                <CustomRoute
                    insert
                    update
                    pageId={PAGE_PERMISSION.VOLT_PROMO_CODE}
                    path={`${match.url}${PROJECT_ROUTE}/promo-code/upsert`}
                    component={asyncComponent(() => {
                        return import("./PromoCode/upsert");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_DAILY_REPORT}
                    path={`${match.url}${PROJECT_ROUTE}/daily-reports`}
                    component={asyncComponent(() => {
                        return import("./DailyReports");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_DISPUTE}
                    path={`${match.url}${PROJECT_ROUTE}/dispute`}
                    component={asyncComponent(() => {
                        return import("./RideDispute");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_CASH_COLLECTION}
                    path={`${match.url}${PROJECT_ROUTE}/cash-collection`}
                    component={asyncComponent(() => {
                        return import("./CashCollection");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_DELIVERY_COLLECTION}
                    path={`${match.url}${PROJECT_ROUTE}/delivery-collection`}
                    component={asyncComponent(() => {
                        return import("./DeliveryCollection");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_TRANSACTION_DETAILS}
                    path={`${match.url}${PROJECT_ROUTE}/transaction-details`}
                    component={asyncComponent(() => {
                        return import("./Transactions");
                    })}
                />
                <CustomRoute
                    pageId={PAGE_PERMISSION.VOLT_REFERRAL}
                    path={`${match.url}${PROJECT_ROUTE}/referral-list`}
                    component={asyncComponent(() => {
                        return import("./Referral");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_NOTIFICATION}
                    path={`${match.url}${PROJECT_ROUTE}/notification`}
                    component={asyncComponent(() => {
                        return import("./Notification");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_RATINGS}
                    path={`${match.url}${PROJECT_ROUTE}/ratings`}
                    component={asyncComponent(() => {
                        return import("./Ratings");
                    })}
                />
                <CustomRoute
                    exact
                    pageId={PAGE_PERMISSION.VOLT_STATIC_PAGE}
                    path={`${match.url}${PROJECT_ROUTE}/static-page`}
                    component={asyncComponent(() => {
                        return import("./StaticPage");
                    })}
                />
                <Route
                    path={`${match.url}${PROJECT_ROUTE}/language`}
                    component={asyncComponent(() => {
                        return import("./Language");
                    })}
                />
                <Route
                    path="/404"
                    component={asyncComponent(() => {
                        return import("./404");
                    })}
                />
                <Route
                    path={`${match.url}${PROJECT_ROUTE}/setup-config`}
                    component={asyncComponent(() => {
                        return import("./SetupConfig");
                    })}
                />
                <Route
                    exact
                    path={`${match.url}${PROJECT_ROUTE}/roles`}
                    component={asyncComponent(() => {
                        return import("./Roles");
                    })}
                />
                <Route
                    path={`${match.url}${PROJECT_ROUTE}/roles/upsert`}
                    component={asyncComponent(() => {
                        return import("./Roles/upsert");
                    })}
                />
                <Route
                    path={`${match.url}${PROJECT_ROUTE}/device-config`}
                    component={asyncComponent(() => {
                        return import("./DeviceConfig");
                    })}
                />
                <Redirect
                    from={`${match.url}${PROJECT_ROUTE}`}
                    to={`${match.url}${PROJECT_ROUTE}/dashboard`}
                />
                <Redirect
                    from={`${match.url}${PROJECT_ROUTE}/`}
                    to={`${match.url}${PROJECT_ROUTE}/dashboard`}
                />
                <Redirect from="*" to="/404" />
            </Switch>
        </div>
    );
};
export default App;
