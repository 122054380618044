import {
    LAYOUT_TYPE_BOXED,
    LAYOUT_TYPE_FRAMED,
    LAYOUT_TYPE_FULL,
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
    THEME_TYPE_DARK
} from "../../constants/ThemeSetting";

import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
    getUser,
    setInitUrl,
    userSignOut,
    setSocketConnection,
    setSocket
} from "appRedux/actions/Auth";
import {
    onLayoutTypeChange,
    onNavStyleChange,
    setThemeType
} from "appRedux/actions/Setting";
import AppLocale from "lngProvider";
import {
    DEFAULT_API_ERROR,
    PROJECT_ROUTE,
    SOCKET_CONNECTION
} from "../../constants/Common";
import ForgotPassword from "../../routes/UserAuth/ForgotPassword";
import { IntlProvider } from "react-intl";
import { LocaleProvider } from "antd";
import MainApp from "./MainApp";
import ResetPassword from "../../routes/UserAuth/ResetPassword";
import VerifyEmail from "../VerifyEmail";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import URLSearchParams from "url-search-params";
import UpdateDefaultPasswordByUser from "../../routes/UserAuth/UpdateDefaultPasswordByUser";
import axios from "util/Api";
import { connect } from "react-redux";
import TermsNConditions from "../../routes/TnC";
import io from "socket.io-client";
import UtilService from "../../services/util";
let socket;
const RestrictedRoute = ({ component: Component, token, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                return token ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: props.location }
                        }}
                    />
                );
            }}
        />
    );
};
class App extends Component {
    setLayoutType = layoutType => {
        if (layoutType === LAYOUT_TYPE_FULL) {
            document.body.classList.remove("boxed-layout");
            document.body.classList.remove("framed-layout");
            document.body.classList.add("full-layout");
        } else if (layoutType === LAYOUT_TYPE_BOXED) {
            document.body.classList.remove("full-layout");
            document.body.classList.remove("framed-layout");
            document.body.classList.add("boxed-layout");
        } else if (layoutType === LAYOUT_TYPE_FRAMED) {
            document.body.classList.remove("boxed-layout");
            document.body.classList.remove("full-layout");
            document.body.classList.add("framed-layout");
        }
    };

    setNavStyle = navStyle => {
        if (
            navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
            navStyle === NAV_STYLE_DARK_HORIZONTAL ||
            navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
            navStyle === NAV_STYLE_ABOVE_HEADER ||
            navStyle === NAV_STYLE_BELOW_HEADER
        ) {
            document.body.classList.add("full-scroll");
            document.body.classList.add("horizontal-layout");
        } else {
            document.body.classList.remove("full-scroll");
            document.body.classList.remove("horizontal-layout");
        }
    };
    errorHandler = error => {
        if (error.response.data.code === "E_UNAUTHORIZED") {
            this.props.userSignOut();
        } else if (error.response.data && !error.response.data.message) {
            error.response.data.message = DEFAULT_API_ERROR;
        }

        return Promise.reject(error.response.data);
    };
    successHandler = response => {
        if (response.data) {
            return Promise.resolve(response.data);
        } else {
            return Promise.resolve(response);
        }
    };
    componentWillMount() {
        axios.interceptors.response.use(
            response => {
                return this.successHandler(response);
            },
            error => {
                return this.errorHandler(error);
            }
        );
        if (this.props.initURL === "") {
            this.props.setInitUrl(this.props.history.location.pathname);
        }
        const params = new URLSearchParams(this.props.location.search);
        if (params.has("theme")) {
            this.props.setThemeType(params.get("theme"));
        }
        if (params.has("nav-style")) {
            this.props.onNavStyleChange(params.get("nav-style"));
        }
        if (params.has("layout-type")) {
            this.props.onLayoutTypeChange(params.get("layout-type"));
        }
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.token) {
            axios.defaults.headers.common[
                "Authorization"
            ] = `JWT ${nextProps.token}`;
        }
        if (nextProps.language) {
            axios.defaults.headers.common["language"] = nextProps.language;
        }
        if (nextProps.token && !nextProps.authUser) {
            this.props.getUser();
        }
        if (
            nextProps.authUser &&
            nextProps.authUser.loginToken &&
            !this.props.isConnectSocket &&
            !this.props.socket
        ) {
            await this.connectSocket(nextProps.authUser);
        }
    }

    onBackButtonEvent = e => {
        e.preventDefault();
        this.props.history.push("/");
    };
    componentDidMount() {
        window.onpopstate = this.onBackButtonEvent;
    }
    connectSocket = async authUser => {
        socket = await io(window.location.origin, {
            query: {
                authorization: authUser && authUser.loginToken.substring(4),
                deviceid: UtilService.getDeviceId()
            }
        });
        await this.props.setSocketConnection(true);
        await this.props.setSocket(socket);
    };
    onUnmount = () => {
        if (!SOCKET_CONNECTION) {
            return;
        }
        socket && socket.disconnect();
        this.props.setSocket(null);
        this.props.setSocketConnection(false);
    };

    render() {
        const {
            match,
            location,
            themeType,
            layoutType,
            navStyle,
            locale,
            token,
            initURL
        } = this.props;
        if (themeType === THEME_TYPE_DARK) {
            document.body.classList.add("dark-theme");
        }

        if (location.pathname === "/") {
            if (token === null) {
                return <Redirect to={`/${PROJECT_ROUTE}/signin`} />;
            } else if (
                initURL === "" ||
                initURL === "/" ||
                initURL === `/${PROJECT_ROUTE}/signin` ||
                initURL === `/${PROJECT_ROUTE}` ||
                initURL === `/${PROJECT_ROUTE}/`
            ) {
                return <Redirect to={`/${PROJECT_ROUTE}/dashboard`} />;
            }

            return <Redirect to={initURL} />;
        }
        this.setLayoutType(layoutType);

        this.setNavStyle(navStyle);

        const currentAppLocale = AppLocale[locale.locale];

        return (
            <LocaleProvider locale={currentAppLocale.antd}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}
                >
                    <Switch>
                        <Route
                            exact
                            path={`/${PROJECT_ROUTE}/signin`}
                            component={SignIn}
                        />
                        <Route
                            exact
                            path={`/${PROJECT_ROUTE}/forgot-password`}
                            component={ForgotPassword}
                        />
                        <Route
                            exact
                            path={`/${PROJECT_ROUTE}/update-default-password`}
                            component={UpdateDefaultPasswordByUser}
                        />
                        <Route
                            exact
                            path={`/${PROJECT_ROUTE}/reset-password/:resetId`}
                            component={ResetPassword}
                        />
                        <Route
                            exact
                            path={`/${PROJECT_ROUTE}/customer-agreement`}
                            component={TermsNConditions}
                        />
                        <Route exact path={`/signup`} component={SignUp} />
                        <Route
                            exact
                            path={`/${PROJECT_ROUTE}/auth/verify-email/:id?`}
                            component={VerifyEmail}
                        />
                        <RestrictedRoute
                            path={`${match.url}`}
                            token={token}
                            component={MainApp}
                        />
                    </Switch>
                </IntlProvider>
            </LocaleProvider>
        );
    }
}

const mapStateToProps = ({ settings, auth }) => {
    const { locale, navStyle, themeType, layoutType, language } = settings;
    const { authUser, token, initURL } = auth;

    return {
        locale,
        token,
        navStyle,
        themeType,
        layoutType,
        authUser,
        initURL,
        language
    };
};
export default connect(mapStateToProps, {
    setInitUrl,
    getUser,
    setThemeType,
    onNavStyleChange,
    onLayoutTypeChange,
    userSignOut,
    setSocketConnection,
    setSocket
})(App);
