import {
    PAGE_PERMISSION
} from "./PagePermission";

export const PERMISSION_TYPE = {
    view: "View",
    list: "List",
    insert: "Insert",
    update: "Update",
    delete: "Delete"
};
export const MENU_LIST_MODULES = [{
    module: PAGE_PERMISSION.VOLT_DASHBOARD,
    name: "Dashboard",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_DRIVERS,
    name: "Drivers",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.VOLT_CUSTOMERS,
    name: "Customers",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.VOLT_USERS,
    name: "Users",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},

{
    module: PAGE_PERMISSION.VOLT_VEHICLES,
    name: "Vehicle",
    keys: ["type", "registerId", "name"],
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.VOLT_VERSION,
    name: "Version",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},

{
    module: PAGE_PERMISSION.VOLT_MASTER,
    name: "Master",
    keys: ["code"],
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.VOLT_SUB_MASTER,
    name: "Sub Master",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.VOLT_SETTINGS,
    name: "Settings",
    permissions: {
        list: true,
        view: false,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.VOLT_ROLES,
    name: "Roles",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.VOLT_FARE_CONFIG,
    name: "Fare Configuration",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.VOLT_FOS,
    name: "FOS",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.VOLT_TIER,
    name: "Tier",
    permissions: {
        list: true,
        view: false,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.VOLT_STATIC_PAGE,
    name: "Static Page",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.VOLT_TIME_SHIFT,
    name: "Time-Shift",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},

{
    module: PAGE_PERMISSION.VOLT_MEMBER_SHIP,
    name: "MemberShip",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.VOLT_RIDE_DETAILS,
    name: "Ride Details",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_DELIVERY_HISTORY,
    name: "Delivery History",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_REFERRAL,
    name: "Referral usage History",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},

{
    module: PAGE_PERMISSION.VOLT_RECURRING_MULTI_HISTORY,
    name: "Volt Recurring Multi Delivery History",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.VOLT_MULTI_DELIVERY,
    name: "Volt Multi Delivery History",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.MICRO_MOBILITY_DELIVERY_HISTORY,
    name: "Micro Mobility Delivery History",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_RIDE_SUMMARY,
    name: "Ride Summary",
    permissions: {
        list: true,
        view: true,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_RECURRING_HISTORY,
    name: "Recurring History",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_SCHEDULE_HISTORY,
    name: "Schedule History",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_REJECTED_RIDES,
    name: "Rejected Rides",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_ATTENDANCE,
    name: "Attendance",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_TIME_SHEET_SUMMARY,
    name: "Time-Sheet Summary",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_TIME_SHEET_DETAILS,
    name: "Time-Sheet Details",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_FARE_COLLECTION,
    name: "Fare Collection",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_COMMISSION_SUMMARY,
    name: "Driver Commission Summary",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_COMMISSION_DETAILS,
    name: "Driver Commission Details",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_PROMO_CODE,
    name: "Promotions",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.VOLT_TRANSACTION_DETAILS,
    name: "Transaction Details",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_NOTIFICATION,
    name: "Notification",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_DAILY_REPORT,
    name: "Daily Report",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_DISPUTE,
    name: "Dispute",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_CASH_COLLECTION,
    name: "Cash Collection",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_DELIVERY_COLLECTION,
    name: "Delivery Collection",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_RATINGS,
    name: "Ratings",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VOLT_CUSTOMER_SERVICE,
    name: "Customer Service",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.SHARE2OWN_SETTING,
    name: 'Share2Own Setting',
    permissions: { list: true, view: false, insert: false, update: true, delete: false }
},
{
    module: PAGE_PERMISSION.SHARE2OWN,
    name: 'Share 2 Own',
    permissions: { list: true, view: true, insert: false, update: true, delete: true }
},
{
    module: PAGE_PERMISSION.VOLT_MOBILITY,
    name: "Mobility",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},

{
    module: PAGE_PERMISSION.DASHBOARD,
    name: "Mobility Dashboard",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.GENERAL_SETTINGS,
    name: "Mobility General Settings",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.GEO_LOCATION,
    name: "Mobility Geo Location",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.USERS,
    name: "Mobility Users",
    keys: ["name", "emails"],
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.RIDERS,
    name: "Mobility Customer",
    keys: ["name", "emails"], //email
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VEHICLES,
    name: "Mobility Vehicle",
    keys: ["type", "registerId", "name"],
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.COMMUNITY_MODE,
    name: "Mobility Community Mode",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.VEHICLE_REPORT,
    name: "Mobility Vehicle Report",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.RIDES,
    name: "Mobility Rides",
    keys: ["rideNumber", "status"], //iot command
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.SUBSCRIPTION,
    name: "Mobility Booking Plan",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
// {
//     module: PAGE_PERMISSION.RENTAL,
//     name: 'Mobility Rental',
//     permissions: { list: true, view: true, insert: true, update: true, delete: true }
// },
// {
//     module: PAGE_PERMISSION.RENTAL_PAYMENT,
//     name: 'Mobility Rental Payment',
//     permissions: { list: true, view: true, insert: true, update: true, delete: true }
// },
// {
//     module: PAGE_PERMISSION.RENTAL_PAYMENT_CLIENT,
//     name: 'Client Payments',
//     permissions: { list: true, view: true, insert: true, update: true, delete: true }
// },
// {
//     module: PAGE_PERMISSION.BOOKING_PASS,
//     name: "Booking Pass",
//     permissions: { list: true, view: true, insert: true, update: true, delete: true }
// },
{
    module: PAGE_PERMISSION.PAYMENT,
    name: "Mobility Payment",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.DISPUTE,
    name: "Mobility Dispute",
    keys: ["uniqNumber", "rideId.rideNumber"],
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.NOTIFICATIONS,
    name: "Mobility Notifications",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.FAQS,
    name: "Mobility FAQs",
    keys: ["question"],
    permissions: {
        list: true,
        view: false,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.STATIC_PAGE,
    name: "Mobility Static Page",
    permissions: {
        list: true,
        view: true,
        insert: false,
        update: true,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.FEEDBACK,
    name: "Mobility Feedback",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.MASTER,
    name: "Mobility Master",
    keys: ["code"],
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.DATABANK,
    name: "Mobility Data Bank",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.RIDE_SETTING,
    name: "Mobility Ride Setting",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: true,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.SUPPORT,
    name: "Mobility Support Information",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: true,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.CANCELLATION_REASON,
    name: "Mobility Cancellation Reason",
    keys: ["reason"],
    permissions: {
        list: true,
        view: false,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.ACTION_QUESTIONNAIRE,
    name: "Mobility Action Questionnaire",
    keys: ["question"],
    permissions: {
        list: true,
        view: false,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.ZONES,
    name: "Mobility Geo Fence",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.NEST,
    name: "Mobility Zone",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
// {
//     module: PAGE_PERMISSION.TASKSETUP,
//     name: 'Task Setup',
//     permissions: { list: true, view: true, insert: true, update: true, delete: true }
// },
// {
//     module: PAGE_PERMISSION.CREATE_TASK,
//     name: 'Create task',
//     permissions: { list: true, view: true, insert: true, update: true, delete: true }
// },
{
    module: PAGE_PERMISSION.FARE_MANAGEMENT,
    name: "Mobility Fare Management",
    permissions: {
        list: true,
        view: false,
        insert: true,
        update: true,
        delete: false
    }
},
// {
//     module: PAGE_PERMISSION.PROCEDURE,
//     name: 'Procedure',
//     keys: ['name'],
//     permissions: { list: true, view: true, insert: true, update: true, delete: true }
// },
{
    module: PAGE_PERMISSION.PROMOTIONS,
    name: "Mobility Promotions",
    keys: ["name", "code"],
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.VERSION,
    name: "Mobility Version",
    permissions: {
        list: true,
        view: false,
        insert: true,
        update: true,
        delete: false
    }
},
// {
//     module: PAGE_PERMISSION.ROLES,
//     name: 'Roles',
//     permissions: { list: true, view: true, insert: true, update: true, delete: true }
// },
{
    module: PAGE_PERMISSION.LOCATION,
    name: "Mobility Location",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
// {
//     module: PAGE_PERMISSION.SERVICE_REQUEST,
//     name: 'Service Request',
//     permissions: { list: true, view: true, insert: true, update: true, delete: true }
// },
// {
//     module: PAGE_PERMISSION.COMMISSION,
//     name: 'Commission',
//     permissions: { list: true, view: true, insert: false, update: true, delete: false }
// },
// {
//     module: PAGE_PERMISSION.COMMISSION_PAYOUT,
//     name: 'Commission Payout',
//     permissions: { list: true, view: true, insert: true, update: true, delete: false }
// },
{
    module: PAGE_PERMISSION.PRIVACY_POLICY,
    name: "Mobility Privacy Policy",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.TERMS_AND_CONDITIONS,
    name: "Mobility T&C",
    permissions: {
        list: true,
        view: true,
        insert: true,
        update: true,
        delete: true
    }
},
{
    module: PAGE_PERMISSION.ABOUT_US,
    name: "Mobility About Us",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.CONTACT_US,
    name: "Mobility Contact Us",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.COMMISSION_REPORT,
    name: "Mobility Commission Report",
    permissions: {
        list: true,
        view: true,
        insert: false,
        update: false,
        delete: false
    }
},
{
    module: PAGE_PERMISSION.WALLET_CONFIG,
    name: "Mobility Wallet Config",
    permissions: {
        list: true,
        view: false,
        insert: false,
        update: true,
        delete: false
    }
}
];
