import React, { Component } from "react";
import { Button, Form, Input, message, Spin } from "antd";
import IntlMessages from "util/IntlMessages";
import { PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH } from "../../../../src/constants/Common";
import UtilService from "../../../../src/services/util";
import SEO from "../../../../src/components/SEO";

import axios from 'util/Api';

const FormItem = Form.Item;


class ResetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            resetId: props.match.params.resetId
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({
                    loading: true
                });
                if (!err) {
                    axios.post(
                        '/auth/reset-password',
                        { token: this.state.resetId, newPassword: values.confirm }
                    ).then((data) => {
                        console.log("data--",data);
                        if (data.code === 'OK') {
                            message.success(`${data.message}`);
                            this.props.form.resetFields();
                            // this.props.history.push('/');
                        } else if (data.message) {
                            message.error(`${data.message}`);
                        }
                        this.setState({
                            loading: false
                        });
                    }).catch((error) => {
                        console.log('Error****:', error.message);
                        this.setState({
                            loading: false
                        });
                        message.error(`${error.message}`);
                    });
                }
            }
        });
    };
    validateAlphaNumPassword = (rule, value, callback) => {
        if (
            value &&
            value.length >= PASSWORD_MIN_LENGTH &&
            !UtilService.checkAlphaNumericPassword(value)
        ) {
            callback("Password must be a combination of number & alphabet and 8 to 15 character long.");
        }
        callback();
    };
    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Password and confirm password is not matching.');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.props.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    render() {
        const { loading } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <div className="gx-login-container">
                <div className="gx-login-content">

                    <div className="gx-login-header">
                        {/* <img src={require("assets/images/logo-black.png")} alt="wieldy" title="wieldy" /> */}
                    </div>
                    <div className="gx-mb-4">
                        <h2>Reset Password</h2>
                        <p><IntlMessages id="appModule.enterPasswordReset" /></p>
                    </div>

                    <Spin spinning={loading} delay={100}>
                        <Form onSubmit={this.handleSubmit} className="gx-login-form gx-form-row0">

                            <FormItem>
                                {getFieldDecorator('password', {
                                    rules: [{
                                        required: true, message: 'Please enter new password.',
                                    },
                                    {
                                        min: 8,
                                        message:
                                            " Password must be 8 to 15 character long."
                                    },
                                    {
                                        validator: this.validateAlphaNumPassword,
                                    },
                                    {
                                        validator: this.validateToNextPassword,
                                    }],
                                })(
                                    <Input type="password" placeholder="New Password" />
                                )}
                            </FormItem>

                            <FormItem>
                                {getFieldDecorator('confirm', {
                                    rules: [{
                                        required: true, message: 'Please enter confirm password.',
                                    },
                                    
                                    {
                                        validator: this.compareToFirstPassword,
                                    }],
                                })(
                                    <Input placeholder="Confirm Password" type="password" onBlur={this.handleConfirmBlur} />
                                )}
                            </FormItem>

                            <FormItem>
                                <Button type="primary" className="gx-mb-0" htmlType="submit">
                                    <IntlMessages id="app.userAuth.reset" />
                                </Button>
                            </FormItem>
                        </Form>
                    </Spin>
                </div>
                <SEO url={this.props.location.pathname.split("/")[2]} />

            </div>
        );
    }
}

const WrappedResetPasswordForm = Form.create()(ResetPassword);

export default (WrappedResetPasswordForm);
